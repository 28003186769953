.Tooltip-Container {
  position: absolute;
  top: -6rem;
  opacity: 0;
  left: -2rem;
  width: 20rem;
  height: 6rem;

  &.AnimationFadeIn {
    animation: fade-in 500ms;
    top: 4.5rem;
    opacity: 1;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &.AnimationFadeOut {
    animation: fade-out 500ms;
    top: 4.5rem;
    opacity: 0;

    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  &.AnimationSlideFromTop {
    animation: slide-from-top 500ms;
    top: 4.5rem;
    opacity: 1;

    @keyframes slide-from-top {
      0% {
        top: -6rem;
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
        top: 4.5rem;
      }
    }
  }

  &.AnimationSlideToTop {
    animation: slide-to-top 500ms;
    top: -6rem;
    opacity: 0;

    @keyframes slide-to-top {
      0% {
        top: 4.5rem;
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        top: -6rem;
        opacity: 0;
      }
    }
  }

  &.AnimationHiddenInTop {
    top: -6rem;
    opacity: 0;
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Arrow {
    position: absolute;
    top: -1.85rem;
    flex: 0;
    width: 5rem;
    height: 5rem;
    z-index: 2;
  }

  .Container {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .Tooltip-Label {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .Icon {
      flex: 1;
      width: 2rem;
      height: 2rem;
      margin-right: 0.85rem;
      transform: translateY(5%);

      &.loyalty {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        transform: translateY(0);
      }
    }

    .Label {
      flex: 1;
      font-size: calc(24px + 2 * (100vw - 320px) / 680);
    }
  }
}

.Mission-Tooltip-Container {
  position: absolute;
  top: -6rem;
  opacity: 0;
  left: -2rem;
  max-width: 25rem;
  max-height: 10rem;

  &.AnimationSlideFromTop {
    animation: slide-from-top 500ms;
    top: 6.2rem;
    opacity: 1;

    @keyframes slide-from-top {
      0% {
        top: -6.2rem;
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
        top: 6.2rem;
      }
    }
  }

  &.AnimationSlideToTop {
    animation: slide-to-top 500ms;
    top: -6rem;
    opacity: 0;

    @keyframes slide-to-top {
      0% {
        top: 4.5rem;
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        top: -6rem;
        opacity: 0;
      }
    }
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cvvTooltip {
  position: absolute;
  width: 14em;
  height: 16em;
  top: 45%;
  right: 0;
  animation: fade-in 1.1s ease-in;

  .cvvTooltipText {
    z-index: 15;
    position: absolute;
    margin: 1.8em 2em;
    text-align: center;

    .cvvTooltipTitle {
      font-size: 1rem;
      font-weight: 400;
    }

    .cvvTooltipDescription {
      font-size: 0.8rem;
    }
  }
}

.TooltipGameUnlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  > * {
    flex: 0;
    max-height: 50%;
  }

  img {
    max-height: 100%;
  }
}

.Events-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: 20rem;
  height: 6rem;

  &.AnimationFadeIn {
    animation: fade-in 500ms;
    opacity: 1;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

