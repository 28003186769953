@import '../../../../../shared/assets/style/theme.scss';

.gtp-modal-reward-center-onboarding {
  max-width: 66%;
}

.RewardCenter {
  color: $common-20;
}

.RewardCenterHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  height: 3.8em;
}

.RewardCenterLoyaltyContainer {
  display: inline-block;
  margin-right: 4em;
  vertical-align: bottom;
  margin-left: auto;
}

.RewardCenterLoyaltyBalance {
  border: 2px solid $secondary-30;
  border-radius: 8% / 50%;
  background: $common-21;
  padding-left: 1em;
  font-size: 1.2em;
  height: 2em;
  line-height: 2;
  min-width: 15em;
  font-feature-settings: 'tnum';
}

.RewardCenterLoyaltyExpiry {
  font-weight: 400;
  text-align: center;
  position: relative;
  bottom: 1.5em;
}

.RewardCenterTabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.RewardCenterTabsDivider {
  position: absolute;
  top: 4.45em;
  width: 100%;
  height: 4px;
}

.RewardCenterTab {
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  z-index: 9;

  img {
    pointer-events: none;
  }

  .indicator {
    width: 100%;
    height: 4px;
    opacity: 0;
    margin: 0;
  }

  &.BackNavigation {
    position: relative;
  }
}

.RewardCenterPropertyPicker {
  width: 50%;
  top: 0;
}

.RewardCenterCategoryPicker {
  top: 0.7em;
  width: 80.5%;
  margin: 0 2rem;
}

.RewardCenterFilterItem {
  padding: 0.4em 0;
  cursor: pointer;
  padding-left: 2em;
  position: relative;

  &.Active,
  &:hover {
    background-color: $common-33;
  }

  &:before {
    position: absolute;
    @include dripIcon();
    @extend .dripicons-chevron-right;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.offerDetailsActionContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 1em;
  width: 35%;
  margin-bottom: 3em;
}

@media only screen and (max-width: 1460px) {
  .offerDetailsActionContainer {
    width: 60%;
  }

  .RewardCenterPropertyPicker {
    width: 100%;
  }

  .OfferDetailAction {
    position: absolute;

    &.ieHack {
      width: 100%;
    }
  }

  .OfferDetailActionHRI {
    position: relative;
    &.ieHack {
      width: 50%;
    }
  }
}

