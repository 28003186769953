.gtp-modal-news {
  max-width: 66%;
}

.newsBody {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'space-between';
  align-items: 'center';
  text-align: 'center';
}