@import 'shared/assets/style/theme.scss';

.form-container {
  &.payment-info {
    .dataContainer {
      margin-right: 15px;
      margin-left: 15px;
    }

    .form-input-label {
      font-size: 1em;
      padding: 0.2rem 0.75rem;

      &.focused {
        top: -15px;
        padding: 0;
      }
    }

    .inputField {
      width: 100%;
      .inputLabel,
      .halfInputField {
        width: 100%;
      }

      .inputWithDropdown {
        display: flex;
        flex-direction: row;
        flex: 1;

        .form-input-group {
          width: 100%;
          display: inline-block;
        }
        .downIcon {
          margin-left: -1.9em;
          align-self: center;
          margin-top: 0.5em;
        }
      }
    }

    .form-confirm-button {
      width: 40%;
      height: 100%;
      margin-top: 3em;

      &.disabled {
        pointer-events: none;
        filter: grayscale(1);
      }
    }

    .message {
      font-size: 16px;
    }
  }
}

