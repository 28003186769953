@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-out-of-coin {
  .modal-content {
    border: none;
  }
  .ModalBackground {
    display: none;
  }

  .outOfCoinContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .quickBuyOfferDetails {
    padding: 0 10px;
    position: absolute;
    bottom: 14%;
    left: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    .quickBuyCoinContainer {
      margin-bottom: -0.5%;
    }

    .quickBuyCoinAmount {
      margin-left: -0.6em;
    }

    .quickBuyLoyaltyAmount {
      margin-left: -1.2em;
      text-shadow: 2px 2px 4px $common-0;
    }

    .quickBuyPlusIcon {
      font-size: 1.5em;
    }
  }

  .outOfCoinButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -5.9%;
  }

  .outOfCoinButton,
  .visitStoreButton {
    position: relative;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;
    width: 23em;

    .outOfCoinButtonLabel,
    .visitStoreButtonLabel {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 1em;
      font-weight: 500;
      text-shadow: 1px 1px 0px $secondary-32, 1px -1px 0px $secondary-32, -1px 1px 0px $secondary-32,
        -1px -1px 0px $secondary-32;
      transform: translate(-50%, -50%);
    }

    .visitStoreButtonLabel {
      text-shadow: 1px 1px 0px $secondary-0, 1px -1px 0px $secondary-0, -1px 1px 0px $secondary-0,
        -1px -1px 0px $secondary-0;
    }
  }
}
