.custom-scrollbar-event-card .os-scrollbar-track {
  background-color: #ecc43470;
  width: 6px;
}

.custom-scrollbar-event-card .os-scrollbar-handle {
  background-color: #ecc434;
  width: 6px;
}

.custom-scrollbar-event-card {
  height: 21vh;
}

